import { Path } from "@/enums/path.enum";
import { PermissionGroupPersona } from "@/enums/permission-group-persona.enum";
import { Route } from "@/enums/route.enum";
import { StayStatus } from "@/enums/stay-status.enum";
import { routes } from "@/helpers/routes.helper";
import { useUserStore } from "@/stores/user.store";

// Order to apply to sort the pages
const pagesOrder = [
	Path.DASHBOARD,
	Path.DASHBOARD_BACKOFFICE,
	Path.SPACES,
	Path.MY_PLAN,
	Path.DOCUMENTS,
	Path.BILLING,
	Path.SETTINGS,
	Path.HELP_CENTER,
	Path.DELIVERY_ADDRESS,
	Path.EVENTS,
	Path.ONBOARDING_SESSIONS,
	Path.BADGE_REQUESTS,
	Path.ASSESSMENTS,
	Path.SOURCES,
	Path.PARKING_SPACES,
	Path.STAYS,
	Path.TOWERS,
	Path.FLATS,
	Path.ROOMS,
	Path.WARNINGS,
	Path.API_TOKENS,
];

// List of all pages, sorted by pagesOrder
export const pages = routes
	.map((route) => {
		// Prepare routes informations
		const routeObj = {
			url: route.path,
			name: route.name,
			permissions: route.meta?.permissions || [],
			restrictions: route.meta?.restrictions || [],
		};

		return {
			// If the current route is dashboard, set it as the default one for redirection
			default: [Route.DASHBOARD, Route.DASHBOARD_BACKOFFICE].includes(route.name),
			...routeObj,
		};
	})
	.sort((itemA, itemB) => {
		// Find index of the item in the ordered list
		const itemAIdx = pagesOrder.findIndex((item) => item === itemA.url);
		const itemBIdx = pagesOrder.findIndex((item) => item === itemB.url);

		// If the items being compared are both from the ordered list, sort the one with the lower index first
		// OR if one of the item isn't in the list, sort the one from the list first
		if (
			(itemAIdx !== -1 && itemBIdx !== -1 && itemAIdx > itemBIdx) ||
			((itemAIdx === -1 || itemBIdx === -1) && itemAIdx < itemBIdx)
		)
			return 1;
		// Same but reversed
		else if (
			(itemAIdx !== -1 && itemBIdx !== -1 && itemAIdx < itemBIdx) ||
			((itemAIdx === -1 || itemBIdx === -1) && itemAIdx > itemBIdx)
		)
			return -1;

		return 0;
	});

// Helper to get authorized pages for the current stay
export const authorizedPages = () => {
	// Get access to userStore
	const userStore = useUserStore();

	// Ensure stay is not in onboarding or canceled or expired
	if (
		![
			StayStatus.CONFIRMED,
			StayStatus.ARRIVING,
			StayStatus.LIVE,
			StayStatus.LEAVING,
			StayStatus.WAITING_REFUND,
			StayStatus.ENDED,
		].includes(userStore.currentStay?.status as StayStatus)
	)
		return [];

	// Filter navigation links to authorized items
	const authorizedPages = Object.values(pages).filter((page) => {
		// Get restrictions for the current page
		const forbiddenToRelative = page.restrictions.find((condition) =>
			condition.for.includes(PermissionGroupPersona.RELATIVES),
		);
		const forbiddenToStaff = page.restrictions.find((condition) =>
			condition.for.includes(PermissionGroupPersona.STAFFS),
		);
		const forbiddenToResident = page.restrictions.find((condition) =>
			condition.for.includes(PermissionGroupPersona.RESIDENTS),
		);

		// If the page is forbidden to relatives and user is one and its stay status isn't a valid one, block access
		if (
			forbiddenToRelative &&
			userStore.currentStay?.isRelative &&
			forbiddenToRelative.when.includes(userStore.currentStay?.status as StayStatus)
		)
			return false;
		// If the page is forbidden to staff and user is one and its stay status isn't a valid one, block access
		else if (
			forbiddenToStaff &&
			[
				PermissionGroupPersona.STAFFS,
				PermissionGroupPersona.ADMINISTRATORS,
				PermissionGroupPersona.OPERATORS,
			].includes(userStore?.role as PermissionGroupPersona) &&
			forbiddenToStaff.when.includes(userStore.currentStay?.status as StayStatus)
		)
			return false;
		// If the page is forbidden to residents and user is one and its stay status isn't a valid one, block access
		else if (
			forbiddenToResident &&
			userStore?.role === PermissionGroupPersona.RESIDENTS &&
			forbiddenToResident.when.includes(userStore.currentStay?.status as StayStatus)
		)
			return false;
		// If no permissions are required OR user has at least one permission, allow access
		else if (page.permissions.length === 0 || userStore.hasOneOfPermissions(page.permissions)) return true;

		// Else, block access
		return false;
	});

	return authorizedPages;
};
