export const pick: <T extends Record<string, unknown>>(object: T, ...keys: [...(keyof T)[]]) => Pick<T, keyof T> = (
	object,
	...keys
) => {
	// Declare an empty object to store new object with only included keys
	const result = {} as { [K in keyof typeof object]: typeof object[K] };

	for (const key in object) if (keys.includes(key)) result[key] = object[key];

	return result;
};
