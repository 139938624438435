import { DepartureReasonType } from "@/enums/departure-reason-type.enum";
import { OnboardingStayDuration } from "@/enums/onboarding-stay-duration.enum";
import { RoomTypeName } from "@/enums/room-type-name.enum";
import { StayStatus } from "@/enums/stay-status.enum";
import http from "@/helpers/http.helper";
import { IFlat } from "@/interfaces/flat.interface";
import { IOrder } from "@/interfaces/order.interface";
import { IPaginated } from "@/interfaces/paginated.interface";
import { IPagination } from "@/interfaces/pagination.interface";
import { IPermissionGroup } from "@/interfaces/permission-group.interface";
import { IRoom } from "@/interfaces/room.interface";
import { ISource } from "@/interfaces/source.interface";
import { IStayCreate } from "@/interfaces/stay-create.interface";
import { IStayOption } from "@/interfaces/stay-option.interface";
import { IStaySourceExpanded } from "@/interfaces/stay-source-expanded.interface";
import { IStayUserExpanded } from "@/interfaces/stay-user-expanded.interface";
import { IStay, IStayExtended } from "@/interfaces/stay.interface";
import { ITower } from "@/interfaces/tower.interface";
import { IUser } from "@/interfaces/user.interface";
import { DateQuery } from "@/types/querying-date";
import { StringQuery } from "@/types/querying-string";

interface IListParams {
	searchTerm?: string;
	status?: StayStatus;
	residents?: boolean;
	tower?: ITower["id"];
	floor?: number;
	flat?: IFlat["id"];
	source?: ISource["id"];
	permissionGroup?: IPermissionGroup["id"];
	roomType?: RoomTypeName;
	stayOptions?: IStayOption["id"];
}

interface IStayList {
	status?: StayStatus | StayStatus[];
	subscription?: StringQuery;
	onboardingStayDuration?: OnboardingStayDuration | OnboardingStayDuration[];
	roomTypeWish?: RoomTypeName;
	roomType?: RoomTypeName;
	isRelative?: boolean;
	arrivalDate?: DateQuery;
	departureDate?: DateQuery;
	departureReasonType?: DepartureReasonType | DepartureReasonType[] | null;
	departureReasonDetails?: StringQuery | null;
	room?: IRoom["id"] | IRoom["id"][];
	user?: IUser["id"] | IUser["id"][];
}

export default {
	async retrieveMe(id: IStay["id"]): Promise<IStay & { permissionGroup: IPermissionGroup }> {
		return await http.$get(`/stays/${id}/me`);
	},

	async listMe(params: IPagination & IOrder): Promise<IPaginated<IStaySourceExpanded>> {
		return await http.$get("/stays/list/me", { params });
	},

	async create(properties: IStayCreate): Promise<IStay> {
		return await http.$post("/stays/me", properties);
	},

	async listMates(): Promise<IPaginated<IStayUserExpanded>> {
		return await http.$get("/stays/list/mates");
	},

	async listUserMates(id: IRoom["id"]): Promise<IPaginated<IStayUserExpanded>> {
		return await http.$get(`/stays/list/mates/${id}`);
	},

	async inviteRelative(properties: { email: string; name: string }): Promise<void> {
		await http.$post("/stays/invite-relative", properties);
	},

	async update(id: IStay["id"], params: Partial<IStay>): Promise<IStay> {
		return await http.$put(`/stays/${id}`, params);
	},

	async updateCodes(): Promise<{ roomSetupPinCode: string; roomResidentPinCode: string }> {
		return await http.$post("/stays/update-my-codes");
	},

	async list(params?: IPagination & IOrder & IStayList): Promise<{ data: IStay[]; total: number }> {
		return await http.$get<{ data: IStay[]; total: number }>("/stays/list", { params });
	},

	async retrieve(id: IStay["id"]): Promise<IStayExtended> {
		return await http.$get(`/stays/${id}`);
	},

	async search(params?: IPagination & IOrder & IListParams): Promise<{ data: IStay[]; total: number }> {
		return await http.$post("/stays/search", params);
	},

	async exportData(params?: IListParams) {
		return await http.$post("/stays/export/data", params);
	},

	async exportMatchingReport() {
		return await http.$post("/stays/export/matching-report");
	},

	async changeRoom(id: IStay["id"], room: IRoom["id"]) {
		return await http.$put(`/stays/${id}/room`, { room });
	},
};
