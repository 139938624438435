import http from "@/helpers/http.helper";
import { IOrder } from "@/interfaces/order.interface";
import { IPagination } from "@/interfaces/pagination.interface";
import { IRoom } from "@/interfaces/room.interface";
import { ISource } from "@/interfaces/source.interface";

export default {
	async create(params: {
		name: string;
		slug: string;
		website: string;
		isExternal: boolean;
		isStaff: boolean;
		isRelatives: boolean;
		isEnabled: boolean;
		fees: string;
	}) {
		return await http.$post("/sources", params);
	},

	async list(
		params?: IPagination & IOrder & { select?: (keyof ISource)[] },
	): Promise<{ data: ISource[]; total: number }> {
		return await http.$get<{ data: ISource[]; total: number }>("/sources/list", { params });
	},

	async retrieve(id: ISource["id"]): Promise<ISource> {
		return await http.$get(`/sources/${id}`);
	},

	async retrieveRestricted(id: ISource["id"]): Promise<ISource> {
		return await http.$get(`/sources/${id}/restricted`);
	},

	async retrieveBySlug(slug: ISource["slug"]): Promise<Pick<ISource, "id" | "name">> {
		return await http.$get(`/sources/slug/${slug}`);
	},

	async update(
		id: IRoom["id"],
		params: {
			name?: string;
			slug?: string;
			website?: string;
			isExternal?: boolean;
			isStaff?: boolean;
			isRelatives?: boolean;
			isEnabled?: boolean;
			fees?: string;
		},
	): Promise<ISource> {
		return await http.$put(`/sources/${id}`, params);
	},

	async delete(id: ISource["id"]): Promise<void> {
		return await http.$delete(`/sources/${id}`);
	},
};
