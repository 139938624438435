import { NotificationType, useAppStore } from "@/stores/app.store";
import { capitalize } from "@/utils/capitalize.util";

export const notify = (notifications?: string | string[], type: NotificationType = "error", duration?: number) => {
	// Get App store
	const appStore = useAppStore();

	// Manage array of notifications
	if (Array.isArray(notifications))
		for (const notification of Array.from(new Set(notifications)))
			appStore.pushNotification(type, capitalize(notification));
	// Manage single notification
	else if (typeof notifications === "string") appStore.pushNotification(type, capitalize(notifications), duration);
};
