export default {
	partial: {
		navigation: {
			public: {
				"signin": "Se connecter",
				"signup": "S'enregistrer",
				"logout": "Déconnexion",
				"logo-alt":
					"Lien vers la page d'accueil contenant toutes les informations à propos de l'extension de co-living de STATION F",
			},
		},
	},
	pages: {
		"gcu": {
			title: "Conditions Générales d'Utilisation",
		},
		"terms-of-services": {
			title: "Conditions Générales de Services",
		},
		"legal-mentions": {
			title: "Mentions légales",
		},
		"privacy-policy": {
			title: "Politique de Confidentialité",
		},
	},
};
