import { Language, LanguageKeys } from "@/enums/language.enum";
import { StoreKey } from "@/enums/store-keys.enum";
import { ISource } from "@/interfaces/source.interface";
import { IStay } from "@/interfaces/stay.interface";
import { IZendeskTicket } from "@/interfaces/zendesk-ticket.interface";
import { defineStore } from "pinia";

export type NotificationType = "info" | "warning" | "error";

export interface INotification {
	id: string;
	type: NotificationType;
	message: string;
	duration?: number;
}

export const useAppStore = defineStore(StoreKey.APP, {
	state: () => ({
		language: Language.EN,
		notifications: [] as INotification[],
		loading: true,
		ticketsIdBeingCreated: [] as IZendeskTicket["id"][],
		partner: undefined as ISource["id"] | undefined,
		primaryStay: undefined as IStay["id"] | undefined,
	}),

	getters: {
		// Reactive getter of notifications
		getNotifications(state) {
			return state.notifications;
		},

		getLocale(state): LanguageKeys | undefined {
			return LanguageKeys[state.language.slice(0, 2).toUpperCase() as LanguageKeys];
		},

		getLanguage(state) {
			return state.language;
		},

		getTicketsIdBeingCreated(state) {
			return state.ticketsIdBeingCreated as IZendeskTicket["id"][];
		},

		getPartner(state) {
			return state.partner;
		},

		getPrimaryStay(state) {
			return state.primaryStay;
		},
	},

	actions: {
		// Append a notification to the state
		async pushNotification(type: NotificationType = "info", message: string, duration?: number) {
			// Assess whether a notification with same message is already part of notifications list
			const notification = this.notifications.find((notification) => notification.message === message);

			// Manage case a notification has been found
			if (notification) this.popNotification(notification.id);

			// Add notification to list
			this.notifications.push({ id: Date.now().toString(), type, message, duration });
		},

		// Remove a notification from the state
		async popNotification(id: string) {
			// Try to find notification
			const notification = this.notifications.findIndex((notification) => notification.id === id);

			// If the notification was found, remove it
			if (notification !== -1) this.notifications.splice(notification, 1);
		},

		// Set global loading flag that prevents the app from rendering
		setLoading(isLoading: boolean) {
			this.loading = isLoading;
		},

		// Set global language
		setLanguage(language: LanguageKeys) {
			this.language = Language[language];
		},

		// Add ticket id to global ticket ids array
		addTicketIdBeingCreated(ticketIdBeingCreated: IZendeskTicket["id"]) {
			this.ticketsIdBeingCreated.push(ticketIdBeingCreated);
		},

		// Remove ticket id from global ticket ids array
		removeTicketIdBeingCreated(index: IZendeskTicket["id"]) {
			this.ticketsIdBeingCreated.splice(index, 1);
		},

		// Update partner based on slug
		setPartner(partner: ISource["id"] | undefined) {
			this.partner = partner;
		},

		// Update primaryStay based on slug
		setPrimaryStay(primaryStay: IStay["id"] | undefined) {
			this.primaryStay = primaryStay;
		},
	},
});
