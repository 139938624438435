import { createI18n } from "vue-i18n";
import en from "@/locales/en";
import fr from "@/locales/fr";

const i18n = createI18n({
	// Set locale
	locale: "en",

	// Set fallback locale
	fallbackLocale: "en",

	// Must be set to 'false', to use Composition API
	legacy: false,

	// Refer a global scope Composer instance of i18n
	globalInjection: true,

	// Set locale messages
	messages: {
		en,
		fr,
	},
});

// Reference to the translate function
const t = i18n.global.t;

export { i18n as i18nPlugin, t };
