import { Path } from "@/enums/path.enum";
import { Route } from "@/enums/route.enum";
import { StayStatus } from "@/enums/stay-status.enum";
import { authorizedPages } from "@/helpers/authorized-pages.helper";
import { useUserStore } from "@/stores/user.store";
import { RouteLocationRaw } from "vue-router";

const dictOnboarding = {
	[StayStatus.APPLICATION]: Route.ONBOARDING_STEP_APPLICATION,
	[StayStatus.IN_REVIEW]: Route.ONBOARDING_STEP_REVIEW,
	[StayStatus.DELAYED]: Route.ONBOARDING_STEP_REVIEW,
	[StayStatus.CONTRACT_PHASE]: Route.ONBOARDING,
} as { [key: string]: string };

export const getDefaultLocation = (): RouteLocationRaw => {
	const userStore = useUserStore();

	// Handle onboarding statuses
	if (userStore.currentStay && userStore.isInOnboarding)
		return { name: dictOnboarding[userStore.currentStay.status] };

	// Handle default switch account when user has stay(s) but no default
	if (!userStore.currentStay || [StayStatus.CANCELED, StayStatus.EXPIRED].includes(userStore.currentStay.status))
		return { name: Route.SWITCH_ACCOUNT };

	if (
		userStore.currentStay &&
		userStore.currentStay?.isRelative &&
		![StayStatus.ARRIVING, StayStatus.LIVE, StayStatus.LEAVING].includes(
			userStore.currentStay?.status as StayStatus,
		)
	)
		return { name: Route.MY_PLAN };

	// Fetch list of authorized pages and find the default one for redirection
	const defaultPage = authorizedPages().find((page) => page.default === true);

	// Redirect user to dashboard
	return defaultPage?.url || Path.HOME;
};
