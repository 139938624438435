import { getDefaultLocation } from "@/helpers/get-default-location";
import { useAppStore } from "@/stores/app.store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const isValidSourceSet = (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext,
) => {
	// Get access to the app store
	const appStore = useAppStore();

	// Continue if no user signed
	if (appStore.getPartner) return next();

	// Assess the default location to redirect to
	const location = getDefaultLocation();

	// Redirect the user to its current default location
	return next(location);
};
