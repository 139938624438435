<script lang="ts" setup>
import { ref, Ref, onBeforeUnmount, watchEffect } from "vue";
import { useAppStore } from "@/stores/app.store";
import Icon from "@/components/ui/icon.vue";
import { X } from "lucide-vue-next";

// Get App store reference
const appStore = useAppStore();

// Reference on the timeout for proper clean on destroy
const timeout: Ref<null | ReturnType<typeof setTimeout>> = ref(null);

// Reactively track the appStore's notifications and re-runs the function whenever they change
const stop = watchEffect(() => {
	// Get the notifications from the app store
	const notifications = appStore.getNotifications;

	// Manage case the notification has a duration
	for (const notification of notifications)
		if (notification?.duration)
			timeout.value = setTimeout(() => dismissNotification(notification.id), notification.duration);
});

// Remove notification from the store
const dismissNotification = (notificationId: string) => appStore.popNotification(notificationId);

// Define icon's type enum
enum Icons {
	info = "CircleAlert",
	warning = "TriangleAlert",
	error = "OctagonAlert",
}

// Return the icon associated with the object's type
const icon = (type: keyof typeof Icons) => Icons[type];

onBeforeUnmount(() => {
	// Clear timeout before component destruction
	if (timeout.value) clearTimeout(timeout.value);

	// Stops the watchEffect on the app store's notifications
	stop();
});
</script>

<template>
	<transition-group
		name="list"
		class="fixed bottom-0 right-1/2 md:right-0 transform translate-x-1/2 md:translate-x-0 mb-4 md:m-8 z-50 grid gap-5 w-9/10 md:max-w-1/3 lg:max-w-1/4"
		tag="div"
	>
		<ui-card
			v-for="notification in appStore.notifications"
			:key="notification.id"
			class="px-4 py-2 flex flex-row justify-start items-start relative"
		>
			<Icon color="red" class="text-primary-gradient-r text-24px" :name="icon(notification.type)" />

			<div class="flex flex-col justify-start items-start ml-2.5 mr-2">
				<h4 class="text-primary-gradient-r capitalize">{{ notification.type }}</h4>
				<p class="text-left text-grey-0" style="word-break: break-word">{{ notification.message }}</p>
			</div>

			<X
				class="text-black text-20px uil uil-times cursor-pointer absolute right-4 top-2"
				@click="dismissNotification(notification.id)"
			/>
		</ui-card>
	</transition-group>
</template>

<style lang="scss" scoped>
.list-enter-active,
.list-leave-active {
	@apply transition-all;
	@apply duration-500;
	@apply ease-in-out;
}
.list-enter-from,
.list-leave-to {
	@apply opacity-0;
	@apply transform;
	@apply translate-x-13;
}
</style>
