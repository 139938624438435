import { Path } from "@/enums/path.enum";
import { notify } from "@/helpers/notify.helper";
import { t } from "@/plugins/i18n";
import { router } from "@/router";
import { HttpError } from "@/types/http-error.type";
import axios, { Axios, AxiosError, AxiosInstance, AxiosRequestConfig } from "axios";
import { useUserStore } from "@/stores/user.store";

const instance: AxiosInstance = axios.create({
	baseURL: process.env.VITE_API_URL,
	headers: {
		"Content-type": "application/json",
	},
	withCredentials: true,
});

instance.interceptors.request.use((config) => {
	// Get user store where the selected stay is held
	const userStore = useUserStore();

	// Add our custom header to the request
	// NB: This allows to select which user's stay is currently used
	if (userStore.currentStayId)
		config.headers = {
			...config.headers,
			"x-current-stay": userStore.currentStayId,
		};

	return config;
});

instance.interceptors.response.use(
	(response) => response,
	async (error: AxiosError) => {
		// Retrieve error code
		const httpResponseStatusCode = Number(error?.response?.status);

		switch (httpResponseStatusCode) {
			// Manage status code corresponding to Bad request errors
			case 400:
				// Display error messages
				notify(error.response?.data.message);

				break;

			// Manage status code corresponding to Unauthorized errors
			case 401: {
				// Get access to userStore
				const userStore = useUserStore();

				// Signout user
				await userStore.signout();

				break;
			}

			// Manage status code corresponding to Forbidden errors
			case 403:
				// Display error messages
				notify(error.response?.data.message || t("helpers.http.403"));

				break;

			// Manage status code corresponding to Not Found errors
			case 404:
				// Display error messages
				if (error.response?.data.message) notify(error.response.data.message);

				break;

			// Manage status code corresponding to Conflict errors
			case 409:
				// Display error messages
				notify(error.response?.data.message || t("helpers.http.409"));

				break;

			// Manage status code corresponding to Unprocessable entity errors
			case 422:
				// Display error messages
				notify(error.response?.data.message);

				break;

			// Manage status 498: Token expired
			case 498:
				try {
					// Retry original request with refreshed tokens
					return axios(error.config);
				} catch (err) {
					// Redirect to homepage
					await router.push(Path.HOME);

					break;
				}

			// Manage internal server error
			case 500:
				// Display error messages
				notify(error.response?.data.message || t("errors.default"));

				break;

			default:
				console.error(`Unhandled error code: ${httpResponseStatusCode}`);

				break;
		}

		throw { code: httpResponseStatusCode, message: error.response?.data.message } as HttpError;
	},
);

export default new (class extends Axios {
	async $get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
		return (await instance.get(url, config)).data;
	}

	async $delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
		return (await instance.delete(url, config)).data;
	}

	async $post<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
		return (await instance.post(url, data, config)).data;
	}

	async $put<T>(url: string, data?: unknown, config?: AxiosRequestConfig): Promise<T> {
		return (await instance.put(url, data, config)).data;
	}
})();
