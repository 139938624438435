import { LegacyInvoiceStatus } from "@/enums/legacy-invoice-status.enum";
import http from "@/helpers/http.helper";
import { ILegacyCreditNote } from "@/interfaces/legacy-credit-note.interface";
import { ILegacyInvoice } from "@/interfaces/legacy-invoice.interface";
import { IOrder } from "@/interfaces/order.interface";
import { IPaginated } from "@/interfaces/paginated.interface";
import { IPagination } from "@/interfaces/pagination.interface";

export default {
	async list(
		params: IPagination &
			IOrder & {
				status?: LegacyInvoiceStatus;
				startDate?: string;
				endDate?: string;
				user?: string;
			},
	): Promise<IPaginated<ILegacyInvoice & { creditNotes: ILegacyCreditNote[] }>> {
		return await http.$get("/invoices/list", { params });
	},

	async listMe(
		params: IPagination &
			IOrder & {
				status?: LegacyInvoiceStatus;
				startDate?: string;
				endDate?: string;
			},
	): Promise<IPaginated<ILegacyInvoice & { creditNotes: ILegacyCreditNote[] }>> {
		return await http.$get("/invoices/me", { params });
	},

	async downloadInvoice(id: ILegacyInvoice["id"]) {
		return new Blob([await http.$post("/invoices/download", { id }, { responseType: "blob" })], {
			type: "application/pdf",
		});
	},

	async downloadInvoiceMe(id: ILegacyInvoice["id"]) {
		return new Blob([await http.$post("/invoices/download/me", { id }, { responseType: "blob" })], {
			type: "application/pdf",
		});
	},

	async downloadCreditNote(invoiceId: ILegacyInvoice["id"], creditNoteId: ILegacyCreditNote["id"]) {
		return new Blob(
			[
				await http.$post(
					"/invoices/download",
					{ id: invoiceId, creditNote: creditNoteId },
					{ responseType: "blob" },
				),
			],
			{
				type: "application/pdf",
			},
		);
	},

	async downloadCreditNoteMe(invoiceId: ILegacyInvoice["id"], creditNoteId: ILegacyCreditNote["id"]) {
		return new Blob(
			[
				await http.$post(
					"/invoices/download/me",
					{ id: invoiceId, creditNote: creditNoteId },
					{ responseType: "blob" },
				),
			],
			{
				type: "application/pdf",
			},
		);
	},
};
