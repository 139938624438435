import { StayStatus } from "@/enums/stay-status.enum";
import { IStay } from "@/interfaces/stay.interface";

// Helper to determine which stay to set as current by default
export const defaultStay = (stays: IStay[]): IStay | null => {
	// If there's only one stay to display, return it
	if (stays.length === 1) return stays[0];

	// Define an array containing all statuses accepted for defining current stay
	const validStatuses = [
		StayStatus.LIVE,
		StayStatus.LEAVING,
		StayStatus.APPLICATION,
		StayStatus.CONTRACT_PHASE,
		StayStatus.IN_REVIEW,
		StayStatus.DELAYED,
		StayStatus.CONFIRMED,
		StayStatus.ARRIVING,
	];

	// Loop on those statuses by order of priority
	for (const status of validStatuses) {
		// Assess whether stays list has a stay with current status
		const validStay = stays.find((stay) => stay.status === status);

		// Manage case a stay with current status has been found
		if (validStay) return validStay;
	}

	// Manage case no stay can be set has current stay
	return null;
};
