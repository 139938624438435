import { InvoiceProductType } from "@/enums/invoice-product-type.enum";
import { StripeInvoiceStatus } from "@/enums/stripe-invoice-status.enum";
import http from "@/helpers/http.helper";
import { IInvoice } from "@/interfaces/invoice.interface";
import { ILegacyInvoice } from "@/interfaces/legacy-invoice.interface";
import { IPaginated } from "@/interfaces/paginated.interface";
import { IPagination } from "@/interfaces/pagination.interface";
import { IPaymentCard } from "@/interfaces/payment-card.interface";
import { IStay } from "@/interfaces/stay.interface";
import LegacyInvoices from "@/services/legacy-invoices.service";
import { Stripe } from "stripe";

export default {
	async updatePaymentMethod(): Promise<string> {
		// Request a payment method update client secret for the logged user
		return await http.$post("/billing/payment-method");
	},

	async retrievePaymentMethod(): Promise<IPaymentCard | null> {
		// Retrieves the user's current payment method
		return await http.$get("/billing/payment-method");
	},

	async retrieveBadgeRequestPrice(): Promise<{ amount: number }> {
		// Retrieves the current badge request price data
		return await http.$get("/billing/prices/badge-request");
	},

	async retrieveInsurancePrice(): Promise<{ amount: number; contractUrl: string }> {
		// Retrieves the current insurance price data
		return await http.$get("/billing/prices/insurance");
	},

	async retrieveRoomTypesPrices(): Promise<{ [key: string]: number | null }> {
		// Retrieves the current room prices
		return await http.$get("/billing/prices/room-types");
	},

	async retrieveMyRoomPrice(): Promise<number> {
		// Retrieves the current room price
		return await http.$get("/billing/prices/my-room");
	},

	async listInvoices(
		params?: IPagination & {
			getCreditNotes: boolean;
			startDate?: string;
			endDate?: string;
			status?: StripeInvoiceStatus;
			listMore?: boolean;
			stripeId?: string;
			product?: InvoiceProductType;
		},
	): Promise<IPaginated<IInvoice>> {
		return await http.$get<IPaginated<IInvoice>>("/billing/invoices", { params });
	},

	async listInvoicesMe(
		params?: IPagination & {
			status?: StripeInvoiceStatus;
			getCreditNotes: boolean;
		},
	): Promise<IPaginated<IInvoice>> {
		return await http.$get<IPaginated<IInvoice>>("/billing/invoices/me", { params });
	},

	async lastInvoice(): Promise<ILegacyInvoice | IInvoice | null> {
		// Retrieve last 5 legacy invoices and last 5 stripe invoices
		const [legacyInvoices, stripeInvoices] = await Promise.all([
			LegacyInvoices.listMe({ take: 5, skip: 0 }),
			http.$get<IPaginated<IInvoice>>("/billing/invoices/me", { params: { take: 5, skip: 0 } }),
		]);

		// Assess last invoice for both legacy and stripe types of invoice
		const lastLegacyInvoice = legacyInvoices.data.find(
			(invoice) => !invoice.name.includes("Frais de dossier") && !invoice.name.includes("nouveau badge"),
		);
		const lastStripeInvoice = stripeInvoices.data.find((invoice) =>
			invoice.billingReason?.includes("subscription"),
		);

		// Manage case a stripe invoice could be found
		return lastStripeInvoice || lastLegacyInvoice || null;
	},

	async getUserInvoiceItems(): Promise<Stripe.InvoiceItem[]> {
		return await http.$get("/billing/invoice-items");
	},

	async retrievePromotionDiscount(stripePromoCode: Stripe.PromotionCode["code"]): Promise<{
		percent_off: Stripe.Coupon["percent_off"];
		amount_off: Stripe.Coupon["percent_off"];
		currency: Stripe.Coupon["currency"];
	}> {
		return await http.$get("/billing/promotion/discount", { params: { stripePromoCode } });
	},

	async isPromoCodeValid(stripePromoCode: Stripe.PromotionCode["code"]): Promise<boolean> {
		return await http.$get("/billing/promotion/valid", { params: { stripePromoCode } });
	},

	async updateSubscription(
		id: IStay["subscription"],
		properties?: { promoCode?: Stripe.PromotionCode["code"] | null },
	) {
		return await http.$put(`/billing/subscriptions/${id}`, properties);
	},

	async retrieveSubscriptionPromoCode(id: IStay["subscription"]): Promise<string | null> {
		return await http.$get(`/billing/subscriptions/${id}/coupon`);
	},

	async paymentsExport(params: { startDate: string; endDate: string }): Promise<void> {
		return await http.$post("/billing/exports/payments", params);
	},

	async salesExport(params: { startDate: string; endDate: string }): Promise<void> {
		return await http.$post("/billing/exports/sales", params);
	},

	async pay(params: { id: IInvoice["id"] }): Promise<Stripe.Invoice> {
		return await http.$post("/billing/invoices/pay", params);
	},
};
