import DatePicker from "@/components/form/date-picker.vue";
import File from "@/components/form/file.vue";
import StripeCard from "@/components/form/stripe-card.vue";
import MultiSelectDropdown from "@/components/form/multi-select-dropdown.vue";
import PackageSelector from "@/components/form/package-selector.vue";
import Password from "@/components/form/password.vue";
import SelectButton from "@/components/form/select-button.vue";
import SingleSelectDropdown from "@/components/form/single-select-dropdown.vue";
import Token from "@/components/form/token.vue";
import { rules } from "@/helpers/form-rules.helper";
import { FormKitNode } from "@formkit/core";
import { en, fr } from "@formkit/i18n";
import { createInput } from "@formkit/vue";

export default {
	// Define additional locales
	locales: { en, fr },

	// Define the active locale
	locale: "en",

	// Global validation message
	messages: {
		en: {
			validation: {
				required() {
					return "Please fill out this field.";
				},
			},
		},
		fr: {
			validation: {
				required() {
					return "Veuillez renseignez ce champ.";
				},
			},
		},
	},

	// Custom inputs
	inputs: {
		// Cloud of togglable options
		selectButton: createInput(SelectButton, { props: ["options", "isMultiple"] }),

		// Dropdown with one value
		singleSelectDropdown: createInput(SingleSelectDropdown, {
			props: [
				"options",
				"placeholder",
				"noOptionsText",
				"closeOnSelect",
				"clearable",
				"searchable",
				"defaultValue",
				"canDeselect",
			],
		}),

		// Dropdown with multiple values
		multiSelectDropdown: createInput(MultiSelectDropdown, {
			props: [
				"options",
				"placeholder",
				"noOptionsText",
				"closeOnSelect",
				"max",
				"hideSelected",
				"required",
				"searchable",
			],
		}),

		// Password input with rules below
		hintedPassword: createInput(Password, { props: ["isDisabled", "isHintDisabled", "isConfirmMatching"] }),

		// Package cards as radio input
		packageSelector: createInput(PackageSelector),

		// Date picker
		datePicker: createInput(DatePicker, {
			props: [
				"calendarCellClassName",
				"calendarClassName",
				"clearable",
				"disabledWeekEndDays",
				"enableTimePicker",
				"format",
				"inputClassName",
				"maxDate",
				"menuClassName",
				"minDate",
				"monthPicker",
				"name",
				"placeholder",
				"preFilled",
				"startDate",
			],
		}),

		// File
		file: createInput(File, {
			props: ["accept", "multiple", "canRemove"],
		}),

		// Token
		token: createInput(Token),

		stripeCard: createInput(StripeCard, { props: ["stripeInstance"] }),
	},

	// Rules usable globally
	rules: {
		customPassword: (node: FormKitNode) => {
			// Match when every password rule match at the same time
			return (
				rules.passwordLength.matcher(node.value as string) &&
				rules.oneLowercase.matcher(node.value as string) &&
				rules.oneUppercase.matcher(node.value as string) &&
				rules.oneNumber.matcher(node.value as string) &&
				rules.oneSpecialChar.matcher(node.value as string)
			);
		},
		passwordLength: (node: FormKitNode) => {
			return rules.passwordLength.matcher(node.value as string);
		},
		oneLowercase: (node: FormKitNode) => {
			return rules.oneLowercase.matcher(node.value as string);
		},
		oneUppercase: (node: FormKitNode) => {
			return rules.oneUppercase.matcher(node.value as string);
		},
		oneNumber: (node: FormKitNode) => {
			return rules.oneNumber.matcher(node.value as string);
		},
		oneSpecialChar: (node: FormKitNode) => {
			return rules.oneSpecialChar.matcher(node.value as string);
		},
		phoneNumber: (node: FormKitNode) => {
			return rules.phoneNumber.matcher(node.value as string);
		},
		regexp: (node: FormKitNode) => {
			try {
				new RegExp(node.value as string);
				return true;
			} catch (error) {
				return false;
			}
		},
	},
};
