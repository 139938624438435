import App from "@/App.vue";
import "@/assets/styles/index.scss";
import FormkitConfig from "@/plugins/formkit-config";
import FormKitPlugins from "@/plugins/formkit-plugins";
import { i18nPlugin } from "@/plugins/i18n";
import posthog from "@/plugins/posthog";
import { router } from "@/router";
import { defaultConfig, plugin } from "@formkit/vue";
import { BrowserTracing } from "@sentry/tracing";
import * as Sentry from "@sentry/vue";
import { VueEternalLoading } from "@ts-pro/vue-eternal-loading";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { Settings } from "luxon";
import mitt from "mitt";
import { createPinia as createStore } from "pinia";
import "virtual:windi.css";
import { createApp } from "vue";
import VueCropperJS from "vue-cropperjs";
import vfmPlugin from "vue-final-modal";

// Build Vue application with App root component
const app = createApp(App);

// Use an instance of mitt as emmiter globally
app.config.globalProperties.emitter = mitt();

// Use an instance of posthog emmiter globally
app.config.globalProperties.posthog = posthog;

// Install the router instance as a plugin
app.use(router);

// Install i18n
app.use(i18nPlugin);

// Install Pinia store as a plugin
app.use(createStore());

// Install FormKit as a plugin
app.use(
	plugin,
	defaultConfig({
		...FormkitConfig,
		plugins: FormKitPlugins,
	}),
);

// Install vue-final-modal as a plugin
app.use(vfmPlugin);

// Register vue-infinite-loading as a global component
app.component("VueEternalLoading", VueEternalLoading);

// Register vue-datepicker as a global component
app.component("Datepicker", Datepicker);

// Register vue-cropper-js as a global component
app.component("VueCropperJs", VueCropperJS);

// Set a default locale for Luxon so that new instances will always be in english for comparison
Settings.defaultLocale = "en-US";

// Enable Sentry (production only)
if (process.env.NODE_ENV === "production")
	Sentry.init({
		app,
		dsn: process.env.VITE_SENTRY_DSN,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ["localhost", "flatmates.stationf.co", /^\//],
			}),
		],
	});

// Mount Vue to the #app element
app.mount("#app");
