export default {
	standard: {
		img: "/assets/standard-room.jpeg",
		advantages: [
			"Shared living room",
			"Shared full kitchen",
			"Shared bathroom for two",
			"Private standard bedroom",
		],
		price: 499,
	},
	premium: {
		img: "/assets/premium-room.jpg",
		advantages: ["Shared living room", "Shared full kitchen", "Private bathroom", "Private large bedroom"],
		price: 699,
	},
	couple: {
		img: "/assets/couple-room.jpeg",
		advantages: [
			"Shared living room",
			"Shared full kitchen",
			"Private bathroom & WC",
			"Private bedroom and additional room",
		],
		price: 899,
	},
};
