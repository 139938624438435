import { FormKitNode } from "@formkit/core";

export default [
	(node: FormKitNode) => {
		// Customization of inputs classes globally
		node.config.rootClasses = (sectionKey: string, node: FormKitNode) => {
			// Extract type of input
			const type = node.props.type;

			const classConfig: Record<string | symbol, unknown> = {
				/* GLOBAL SECTION KEYS (applicable to almost all types) */
				// The outermost wrapping element.
				outer: `formkit-${sectionKey}`,

				// A wrapper around the label and input.
				wrapper: `formkit-${sectionKey} flex flex-col`,

				// The label of the input.
				label() {
					const base = "text-black font-sans";

					if (type === "radio") return `formkit-radio-label ${base}`;
					if (type === "checkbox") return `formkit-checkbox-label ${base}`;
					else return `formkit-${sectionKey} ${base} uppercase text-xs leading-xs font-bold mb-2`;
				},

				// Has output by default, but allows content directly before an input element.
				prefix: `formkit-${sectionKey}`,

				// A wrapper around the actual input element.
				inner: `formkit-${sectionKey} w-full`,
				// inner: null,

				// Has output by default, but allows content directly after an input element.
				suffix: `formkit-${sectionKey}`,

				// The input element itself.
				input() {
					const base = "w-full";

					if (type === "radio") return `formkit-radio-${sectionKey} ${base}`;
					else
						return `formkit-${sectionKey} ${base} bg-secondary-2 rounded-sm px-6 py-4 font-sans text-sm leading-sm`;
				},

				// The element containing help text.
				help: `formkit-${sectionKey} text-xs leading-xs text-grey font-normal`,

				// A wrapper around all the messages.
				messages: `formkit-${sectionKey}`,

				// The element (or many elements) containing a message — most often validation and error messages.
				message: `formkit-${sectionKey}`,

				/* CHECKBOXES & RADIO & SELECT */
				// Responsible for the wrapper around each item in the options.
				option() {
					if (type === "checkbox") return `formkit-checkbox-${sectionKey}`;
					return `formkit-${sectionKey}`;
				},

				// Responsible for the wrapper element around all of the option items.
				options: `formkit-${sectionKey}`,

				/* CHECKBOXES & RADIO */
				// Responsible for the element immediately following the input element — usually used for styling.
				decorator: `formkit-${sectionKey}`,

				// Responsible for the fieldset’s legend element.
				legend: `formkit-${sectionKey} text-black font-sans uppercase text-xs leading-xs font-bold mb-2`,

				// Responsible for the fieldset when multiple options are available.
				fieldset: `formkit-${sectionKey}`,

				/* SELECT */
				// When defined, FormKit injects a non-selectable hidden option tag as the first value of the list to serve as a placeholder.
				placeholder: `formkit-${sectionKey}`,
			};

			// Helper function to transform the class object into strings
			const createClassObject = (classesArray: string) => {
				const classList: { [key: string]: boolean } = {};

				if (typeof classesArray !== "string") return classList;

				classesArray.split(" ").forEach((className) => {
					classList[className] = true;
				});

				return classList;
			};

			// Find the styling associated to requested key
			const target = classConfig[sectionKey];

			// Transform multiple classes' strings into an object for Windi
			if (typeof target === "string") return createClassObject(target);
			else if (typeof target === "function") return createClassObject(target());

			// if no matches return an empty object
			return {};
		};
	},

	// Middleware that removes the inner div for checkboxes and radio inputs in order to style the label
	(inputNode: FormKitNode) => {
		inputNode.on("created", ({ payload: node }) => {
			// Update the schema for checkboxes and radios only
			if (
				(node.props?.type === "checkbox" || node.props?.type === "radio") &&
				typeof node.props.definition.schema === "function"
			) {
				// Clone props definition to prevent deep object referencing
				const definition = { ...node.props.definition };

				// Reference original schema
				const schema = definition.schema;

				// Replace the schema with another higher-order-function
				definition.schema = function (extensions = {}) {
					const ext = {
						...extensions,
						...{ inner: { $el: null }, messages: { $el: null }, message: { $el: "div" } },
					};

					// Finally call the original schema, with extensions applied
					return schema(ext);
				};

				// Replace the input definition
				node.props.definition = definition;
			}
		});
	},

	// Cast all number input value into number
	(node: FormKitNode) => {
		if (node.props.type === "number")
			node.hook.input((value: string | number | null | undefined, next) => {
				// If value is a string, cast string as number with decimals (and remove extra decimals)
				if (value && typeof value === "string")
					return next(parseFloat(value.replace(/^(\d+[.,]\d{0,2})(\d*)$/gm, "$1")));
				// If value is already a number, ensure to parse it with no more than 2 decimals
				else if (value !== null && value !== undefined && typeof value === "number")
					return next(parseFloat(value.toFixed(2)));

				// If type is neither string nor number, return null (meaning there was a problem)
				return next(null);
			});
	},
];
