import http from "@/helpers/http.helper";
import { IOrder } from "@/interfaces/order.interface";
import { IPaginated } from "@/interfaces/paginated.interface";
import { IPagination } from "@/interfaces/pagination.interface";
import { IStay } from "@/interfaces/stay.interface";
import { IUser } from "@/interfaces/user.interface";
import { IUserUpdateMe } from "@/interfaces/user-update-me.interface";
import { IUserUpdate } from "@/interfaces/user-update.interface";

interface IListParams {
	id?: string;
	email?: string;
}

export default {
	async getMe(): Promise<IUser & { stays: IStay[] }> {
		return await http.$get("/users/me");
	},

	async list(params?: IPagination & IOrder & IListParams): Promise<IPaginated<IUser>> {
		return await http.$get<IPaginated<IUser>>("/users/list", { params });
	},

	async search(params?: { searchTerm?: string }): Promise<IUser[]> {
		return await http.$get<IUser[]>("/users/search", { params });
	},

	async searchLogin(login: string): Promise<IUser> {
		return await http.$get<IUser>(`/users/search/${login}`);
	},

	async updateMe(properties: IUserUpdateMe) {
		return await http.$put("/users/me", properties);
	},

	async update(id: IUser["id"], properties: IUserUpdate) {
		return await http.$put(`/users/${id}`, properties);
	},

	async updateAvatar(fd: FormData) {
		return await http.$post("/users/avatar", fd);
	},

	async deleteAvatar() {
		return await http.$post("/users/avatar", { avatar: null });
	},

	async viewCard(id: IUser["id"]): Promise<Blob> {
		return await http.$get<Blob>(`/users/${id}/card`, { responseType: "blob" });
	},
};
