import posthog, { PostHog } from "posthog-js";

class PosthogSingleton {
	private static instance: PostHog;

	private constructor() {
		// Empty PRIVATE constructor
	}

	public static getInstance(): PostHog {
		if (!PosthogSingleton.instance) {
			posthog.init(
				process.env.NODE_ENV === "development"
					? (process.env.VITE_POSTHOG_KEY_DEV as string)
					: (process.env.VITE_POSTHOG_KEY_PROD as string),
				{
					api_host: process.env.VITE_POSTHOG_URL as string,
				},
			);
			PosthogSingleton.instance = posthog;
		}

		return PosthogSingleton.instance;
	}
}

export default PosthogSingleton.getInstance();
