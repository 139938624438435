<script lang="ts">
import { computed, defineComponent } from "vue";

// Define component background colors dict
const colorsDict = {
	purple: "from-purple-0 to-purple-1",
	orange: "from-orange-0 to-orange-1",
	blue: "from-blue-0 to-blue-1",
	pink: "from-pink-0 to-pink-1",
	secondary: "from-secondary-1 to-secondary-0",
	primary: "from-primary-1 to-primary-0",
	grey: "bg-grey-0",
	green: "bg-green",
};

export default defineComponent({
	props: {
		variant: {
			type: String,
			default: "primary",
			validator: (prop: string) => Object.keys(colorsDict).includes(prop),
		},
	},

	setup(props) {
		// Link component prop to template color classes
		const colorClasses = computed(() => colorsDict[props.variant as keyof typeof colorsDict]);

		return { colorClasses };
	},
});
</script>

<template>
	<span
		class="py-1 px-2 rounded-sm text-white text-xs leading-xs font-bold bg-gradient-to-r w-max"
		:class="colorClasses"
	>
		<slot />
	</span>
</template>
