import http from "@/helpers/http.helper";
import { ISignUp } from "@/interfaces/signup.interface";

export default {
	async signin(input: { email: string; password: string; token?: string }): Promise<void> {
		await http.$post("/auth/signin", input);
	},

	async signout(): Promise<void> {
		return await http.$post("/auth/signout");
	},

	async signinAsUser(email: string, isClipboard: boolean): Promise<string> {
		return await http.$post("/auth/signin-as-user", { email, password: "", isClipboard });
	},

	async signup(input: ISignUp): Promise<void> {
		await http.$post("/auth/signup", input);
	},

	async forgotPassword(input: { email: string }): Promise<void> {
		return await http.$post("/auth/forgot-password", input);
	},

	async validateToken(input: { token: string }): Promise<void> {
		return await http.$post("/auth/validate-token", input);
	},

	async resetPassword(input: { token: string; password: string }): Promise<void> {
		return await http.$post("/auth/reset-password", input);
	},
};
