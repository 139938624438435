import { Path } from "@/enums/path.enum";
import { Route } from "@/enums/route.enum";
import { SocketMessage } from "@/enums/socket-message.enum";
import { hasOneOfPermissions } from "@/guards/has-one-of-permissions";
import { routes } from "@/helpers/routes.helper";
import { useAppStore } from "@/stores/app.store";
import { useSocketStore } from "@/stores/socket.store";
import { useUserStore } from "@/stores/user.store";
import { useCookies } from "@vueuse/integrations/useCookies";
import { createRouter, createWebHistory, START_LOCATION } from "vue-router";

const Playground = () => import("@/pages/playground/index.vue");

// Create the router instance with history mode
const router = createRouter({
	history: createWebHistory(),
	routes,
	scrollBehavior(to, from, savedPosition) {
		// Handle the native-like behavior when navigating with back/forward buttons
		if (savedPosition) return savedPosition;

		// Handle the "scroll to anchor" behavior
		if (to.hash) return { el: to.hash, behavior: "smooth" };

		// Scroll to top
		return { top: 0 };
	},
});

// If the project has been started in dev mode, add the playground route
process.env.NODE_ENV === "development"
	? router.addRoute({ path: Path.PLAYGROUND, component: Playground, name: Route.PLAYGROUND })
	: null;

// Global guard for each route used to toggle 'loading' flag on app, avoiding blink effect
router.beforeResolve(() => {
	// Access to app store
	const appStore = useAppStore();

	// Remove loader (if set)
	if (appStore.loading) useAppStore().setLoading(false);
});

// Global guard for each route to ensure user has required permissions to access each one
router.beforeEach(async (to, from, next) => {
	// Get access to userStore
	const userStore = useUserStore();

	// Reactive method to fetch cookies
	const cookies = useCookies(["stationf.auth.flatmates"]);

	// Get auth cookie
	const cookie = cookies.get("stationf.auth.flatmates");

	// Flag to know whether user has no live stay but an array of valid stays ad it's trying to access /switch-account
	const hasNoLiveStay = !userStore.permissions && userStore.user?.stays.length && to.path === Path.SWITCH_ACCOUNT;

	if (!(from === START_LOCATION && cookie && !userStore.permissions && !hasNoLiveStay)) {
		// Go through the permission guard
		hasOneOfPermissions(to, from, next);
		return;
	}

	// If this is this initialisation of the router and an auth cookie was found but the store isn't initialised
	// Get access to appStore and socketStore
	const socketStore = useSocketStore();
	const appStore = useAppStore();

	try {
		// Get user's infos and store them
		await userStore.populate();

		// Connect sockets
		socketStore.emit(SocketMessage.USER_SIGNIN, {
			user: userStore.getUser,
			role: userStore.getUserRole,
		});
	} finally {
		// Set global app loading to false
		appStore.setLoading(false);
	}

	// Go through the permission guard
	hasOneOfPermissions(to, from, next);
});

export { router };
