import { Path } from "@/enums/path.enum";
import { PermissionName } from "@/enums/permission-name.enum";
import { StayStatus } from "@/enums/stay-status.enum";
import { StoreKey } from "@/enums/store-keys.enum";
import { defaultStay } from "@/helpers/current-stay.helper";
import { IOnboardingSession } from "@/interfaces/onboarding-session.interface";
import { IPermissionGroup } from "@/interfaces/permission-group.interface";
import { IStayOnboardingPreferences } from "@/interfaces/stay-onboarding-preferences.interface";
import { IStay } from "@/interfaces/stay.interface";
import { IUser } from "@/interfaces/user.interface";
import posthog from "@/plugins/posthog";
import { router } from "@/router";
import authService from "@/services/auth.service";
import staysService from "@/services/stays.service";
import usersService from "@/services/users.service";
import { useAppStore } from "@/stores/app.store";
import * as Sentry from "@sentry/vue";
import { useCookies } from "@vueuse/integrations/useCookies";
import { defineStore } from "pinia";

export const useUserStore = defineStore(StoreKey.USER, {
	state: () => ({
		// User
		user: null as null | IUser,

		// Id of the currently displayed/selected stay
		currentStayId: null as null | string,

		// Permissions linked to the current stay
		permissions: null as null | PermissionName[],

		// User's role
		role: null as null | IPermissionGroup["persona"],
	}),

	actions: {
		reset() {
			this.user = null;
			this.currentStayId = null;
			this.permissions = null;
			this.role = null;
		},

		setUser(user: IUser) {
			this.user = user;
		},

		async setCurrentStay(stayId: IStay["id"]) {
			this.currentStayId = stayId;

			// Fetch stay data (for its stay and permissions)
			const stay = await staysService.retrieveMe(stayId);

			// Update the stay in store
			this.user!.stays[this.user!.stays.findIndex((stay) => stay.id === this.currentStayId)] = stay;

			// Update the permissions in store
			this.permissions = stay.permissionGroup.permissions.map((permission) => permission.name);

			// Update user role
			this.role = stay.permissionGroup.persona;
		},

		setRoomCodes(roomResidentPinCode: string) {
			// Find index of the user's currently selected stay
			const currentStayIdx = this.user!.stays.findIndex((stay) => stay.id === this.currentStayId);

			// Prevent modifying an undefined value
			if (currentStayIdx === -1) return;

			// Update resident pin code
			this.user!.stays[currentStayIdx] = {
				...this.user!.stays[currentStayIdx],
				roomResidentPinCode,
			};
		},

		async signout() {
			// Get access to the app store
			const appStore = useAppStore();
			const cookie = useCookies();

			try {
				// Trigger general loading inside app to avoid display of 'undefined' & co
				appStore.setLoading(true);

				// API call to signout only when cookie is present
				if (cookie.get("stationf.auth.flatmates")) await authService.signout();

				// Clean store
				this.reset();

				// Clean posthog
				posthog.reset(true);

				// Redirect to sign-in page
				await router.push(Path.SIGNIN);
			} finally {
				// Turn off general loading
				appStore.setLoading(false);
			}
		},

		_sentry(user: IUser) {
			try {
				Sentry.configureScope((scope) =>
					scope.setUser({
						id: user.id,
						email: user.email,
						name: user.fullName,
					}),
				);
			} catch (error) {
				Sentry.captureException(new Error("An error occured while setting up Sentry user context."));
			}
		},

		async _posthog(user: IUser) {
			// Identify user on Posthog
			let identified = false;

			do
				try {
					posthog.identify(`user_${user.id}`, {
						id: user.id,
						user: user.fullName,
					});
					identified = true;
				} catch (error) {
					await new Promise((resolve) => setTimeout(() => resolve(null), 100));
				}
			while (!identified);
		},

		async populate() {
			// Fetch the current user
			const user = await usersService.getMe();

			// Store user info
			this.setUser(user);

			// Get potential default stay
			const currentStay = defaultStay(user.stays);

			// Identify user on Posthog
			this._posthog(user);

			// Add user context to every Sentry capture
			this._sentry(user);

			// Handle default stay present
			if (currentStay) return await this.setCurrentStay(currentStay.id);

			// If not default stay was found, redirect to /switch-account
			await router.push(Path.SWITCH_ACCOUNT);
		},

		setStayOnboardingSession(sessionId: IOnboardingSession["id"]) {
			// Find index of the user's currently selected stay
			const currentStayIdx = this.user!.stays.findIndex((stay) => stay.id === this.currentStayId);

			// Prevent modifying an undefined value
			if (currentStayIdx === -1) return;

			// Update the selected onboarding session
			this.user!.stays[currentStayIdx].onboardingSession = sessionId;
		},

		setStayMatesWishes(matesWishes: string[]) {
			// Find index of the user's currently selected stay
			const currentStayIdx = this.user!.stays.findIndex((stay) => stay.id === this.currentStayId);

			// Prevent modifying an undefined value
			if (currentStayIdx === -1) return;

			// Update the mates wishes
			this.user!.stays[currentStayIdx].matesWishes = matesWishes;
		},

		setStayPreferences(preferences: IStayOnboardingPreferences) {
			// Find index of the user's currently selected stay
			const currentStayIdx = this.user!.stays.findIndex((stay) => stay.id === this.currentStayId);

			// Prevent modifying an undefined value
			if (currentStayIdx === -1) return;

			// Update preferences
			Object.assign(this.user!.stays[currentStayIdx], preferences);
		},

		setStayStatus(status: StayStatus) {
			// Find index of the user's currently selected stay
			const currentStayIdx = this.user!.stays.findIndex((stay) => stay.id === this.currentStayId);

			// Prevent modifying an undefined value
			if (currentStayIdx === -1) return;

			// Update stay status
			this.user!.stays[currentStayIdx].status = status;
		},
	},

	getters: {
		// Return the information stored about user
		getUser(state) {
			return state.user;
		},

		// Return user's role
		getUserRole(state) {
			return state.role;
		},

		// Return the information stored about the current stay
		currentStay(state) {
			return state.user?.stays.find((stay) => stay.id === state.currentStayId);
		},

		// Return a boolean that indicate if the user has multiple stays
		hasMultipleStays(state) {
			// If the user hasn't been initialized, return false
			if (!state.user?.stays) return false;

			return state.user?.stays.length > 1;
		},

		// Return a boolean ruling whether the user has the required permission
		hasThisPermission(state) {
			// Single permission passed as props
			return (permission: PermissionName) => state.permissions?.includes(permission);
		},

		// Return a boolean ruling whether the user has at least one of the required permissions
		hasOneOfPermissions(state) {
			return (permissionNames: PermissionName[]) =>
				permissionNames.some((permissionName) => Boolean(state.permissions?.includes(permissionName)));
		},

		// Return a boolean that indicate if the stay is in onboarding
		isInOnboarding(state) {
			// Retrieve current stay
			const stay = state.user?.stays.find((stay) => stay.id === state.currentStayId);

			// Return true in case stay is undefined
			if (!stay) return true;

			return [
				StayStatus.APPLICATION,
				StayStatus.IN_REVIEW,
				StayStatus.DELAYED,
				StayStatus.CONTRACT_PHASE,
			].includes(stay.status);
		},
	},
});
