import { t } from "@/plugins/i18n";
import { isValidPhoneNumber } from "libphonenumber-js";

// Rule definitions with messages
export const rules = {
	passwordLength: {
		matcher: (matchee: string): boolean => Boolean(matchee) && matchee.match(/^.{12,64}$/) !== null,
		message: t("helpers.password-rules.length"),
	},
	oneLowercase: {
		matcher: (matchee: string): boolean => Boolean(matchee) && matchee.match(/[a-z]+/) !== null,
		message: t("helpers.password-rules.oneLowercase"),
	},
	oneUppercase: {
		matcher: (matchee: string): boolean => Boolean(matchee) && matchee.match(/[A-Z]+/) !== null,
		message: t("helpers.password-rules.oneUppercase"),
	},
	oneNumber: {
		matcher: (matchee: string): boolean => Boolean(matchee) && matchee.match(/\d+/) !== null,
		message: t("helpers.password-rules.oneNumber"),
	},
	oneSpecialChar: {
		matcher: (matchee: string): boolean =>
			Boolean(matchee) && matchee.match(/[@$!%*?&\-_+=€§#~£.;\\|'":<>,/?~]+/) !== null,
		message: t("helpers.password-rules.oneSpecialChar"),
	},
	phoneNumber: {
		matcher: (matchee: string): boolean => {
			// Prevent phone number validation if none has been passed
			if (!matchee) return false;

			// Validate phone number string using a third party lib
			return isValidPhoneNumber(matchee);
		},
		message: t("form.phone.validation"),
	},
};
