import { HeaderType } from "@/enums/header-type.enum";
import { Path } from "@/enums/path.enum";
import { PermissionGroupPersona } from "@/enums/permission-group-persona.enum";
import { PermissionName } from "@/enums/permission-name.enum";
import { Route } from "@/enums/route.enum";
import { StayStatus } from "@/enums/stay-status.enum";
import { isInOnboarding } from "@/guards/is-in-onboarding";
import { isNotInOnboarding } from "@/guards/is-not-in-onboarding";
import { isNotSignedIn } from "@/guards/is-not-signed-in";
import { isValidSourceSet } from "@/guards/is-valid-source-set";

const ApiTokens = () => import("@/pages/api-tokens/index.vue");
const ApiTokensCreate = () => import("@/pages/api-tokens/create.vue");
const ApiTokensDetails = () => import("@/pages/api-tokens/details.vue");
const ApiTokensEdit = () => import("@/pages/api-tokens/edit.vue");
const ApiTokensList = () => import("@/pages/api-tokens/list.vue");
const Assessments = () => import("@/pages/assessments/index.vue");
const AssessmentsCreate = () => import("@/pages/assessments/create.vue");
const AssessmentsDetails = () => import("@/pages/assessments/details.vue");
const AssessmentsFill = () => import("@/pages/assessments/fill.vue");
const AssessmentsList = () => import("@/pages/assessments/list.vue");
const AssessmentsSign = () => import("@/pages/assessments/sign.vue");
const BadgeRequests = () => import("@/pages/badge-requests/index.vue");
const BadgeRequestsDetails = () => import("@/pages/badge-requests/details.vue");
const BadgeRequestsList = () => import("@/pages/badge-requests/list.vue");
const Billing = () => import("@/pages/billing/index.vue");
const Dashboard = () => import("@/pages/dashboard/index.vue");
const DashboardBackoffice = () => import("@/pages/dashboard-backoffice/index.vue");
const DeliveryAddress = () => import("@/pages/delivery-address/index.vue");
const Documents = () => import("@/pages/documents/index.vue");
const Events = () => import("@/pages/events/index.vue");
const EventsCreate = () => import("@/pages/events/create.vue");
const EventsDetails = () => import("@/pages/events/details.vue");
const EventsEdit = () => import("@/pages/events/edit.vue");
const EventsList = () => import("@/pages/events/list.vue");
const Flats = () => import("@/pages/flats/index.vue");
const FlatsCreate = () => import("@/pages/flats/create.vue");
const FlatsDetails = () => import("@/pages/flats/details.vue");
const FlatsEdit = () => import("@/pages/flats/edit.vue");
const FlatsList = () => import("@/pages/flats/list.vue");
const ForgotPassword = () => import("@/pages/auth/forgot-password.vue");
const GCU = () => import("@/pages/gcu/index.vue");
const HelpCenter = () => import("@/pages/help-center/index.vue");
const HelpCenterArticles = () => import("@/pages/help-center/sectionId/articleId/details.vue");
const HelpCenterList = () => import("@/pages/help-center/list.vue");
const HelpCenterSections = () => import("@/pages/help-center/sectionId/details.vue");
const HelpCenterTickets = () => import("@/pages/help-center/tickets/index.vue");
const HelpCenterTicketsCreate = () => import("@/pages/help-center/tickets/create.vue");
const HelpCenterTicketsDetails = () => import("@/pages/help-center/tickets/details.vue");
const Home = () => import("@/pages/home/index.vue");
const LegalMentions = () => import("@/pages/legal-mentions/index.vue");
const MyPlan = () => import("@/pages/my-plan/index.vue");
const NotFound = () => import("@/pages/404/index.vue");
const Onboarding = () => import("@/pages/onboarding/index.vue");
const OnboardingDocusignRedirect = () => import("@/pages/onboarding/docusign-redirect.vue");
const OnboardingSessions = () => import("@/pages/onboarding-sessions/index.vue");
const OnboardingSessionsCreate = () => import("@/pages/onboarding-sessions/create.vue");
const OnboardingSessionsDetails = () => import("@/pages/onboarding-sessions/details.vue");
const OnboardingSessionsEdit = () => import("@/pages/onboarding-sessions/edit.vue");
const OnboardingSessionsList = () => import("@/pages/onboarding-sessions/list.vue");
const OnboardingStepApplication = () => import("@/pages/onboarding/step-application.vue");
const OnboardingStepContract = () => import("@/pages/onboarding/step-contract.vue");
const OnboardingStepMates = () => import("@/pages/onboarding/step-mates.vue");
const OnboardingStepPackage = () => import("@/pages/onboarding/step-package.vue");
const OnboardingStepPayment = () => import("@/pages/onboarding/step-payment.vue");
const OnboardingStepPreferences = () => import("@/pages/onboarding/step-preferences.vue");
const OnboardingStepReview = () => import("@/pages/onboarding/step-review.vue");
const OnboardingStepThanks = () => import("@/pages/onboarding/step-thanks.vue");
const ParkingSpaces = () => import("@/pages/parking-spaces/index.vue");
const ParkingSpacesCreate = () => import("@/pages/parking-spaces/create.vue");
const ParkingSpacesDetails = () => import("@/pages/parking-spaces/details.vue");
const ParkingSpacesEdit = () => import("@/pages/parking-spaces/edit.vue");
const ParkingSpacesList = () => import("@/pages/parking-spaces/list.vue");
const PrivacyPolicy = () => import("@/pages/privacy-policy/index.vue");
const ResetPassword = () => import("@/pages/auth/reset-password.vue");
const Rooms = () => import("@/pages/rooms/index.vue");
const RoomsCreate = () => import("@/pages/rooms/create.vue");
const RoomsDetails = () => import("@/pages/rooms/details.vue");
const RoomsEdit = () => import("@/pages/rooms/edit.vue");
const RoomsList = () => import("@/pages/rooms/list.vue");
const Settings = () => import("@/pages/settings/index.vue");
const Signin = () => import("@/pages/auth/signin.vue");
const Signup = () => import("@/pages/auth/signup.vue");
const Sources = () => import("@/pages/sources/index.vue");
const SourcesCreate = () => import("@/pages/sources/create.vue");
const SourcesDetails = () => import("@/pages/sources/details.vue");
const SourcesEdit = () => import("@/pages/sources/edit.vue");
const SourcesList = () => import("@/pages/sources/list.vue");
const Spaces = () => import("@/pages/spaces/index.vue");
const SpacesList = () => import("@/pages/spaces/list.vue");
const SpacesView = () => import("@/pages/spaces/view.vue");
const Statistics = () => import("@/pages/statistics/index.vue");
const Stays = () => import("@/pages/stays/index.vue");
const StaysDetails = () => import("@/pages/stays/details.vue");
const StaysList = () => import("@/pages/stays/list.vue");
const SwitchAccount = () => import("@/pages/auth/switch-account.vue");
const TermsOfServices = () => import("@/pages/terms-of-services/index.vue");
const Towers = () => import("@/pages/towers/index.vue");
const TowersCreate = () => import("@/pages/towers/create.vue");
const TowersDetails = () => import("@/pages/towers/details.vue");
const TowersEdit = () => import("@/pages/towers/edit.vue");
const TowersList = () => import("@/pages/towers/list.vue");
const Warnings = () => import("@/pages/warnings/index.vue");
const WarningsCreate = () => import("@/pages/warnings/create.vue");
const WarningsList = () => import("@/pages/warnings/list.vue");
const Invoices = () => import("@/pages/invoices/index.vue");
const Invoiceslist = () => import("@/pages/invoices/list.vue");

const allStatuses = [
	StayStatus.APPLICATION,
	StayStatus.IN_REVIEW,
	StayStatus.DELAYED,
	StayStatus.CONTRACT_PHASE,
	StayStatus.ARRIVING,
	StayStatus.CONFIRMED,
	StayStatus.LIVE,
	StayStatus.LEAVING,
	StayStatus.WAITING_REFUND,
	StayStatus.ENDED,
	StayStatus.EXPIRED,
	StayStatus.CANCELED,
];

export const routes = [
	// Public
	{
		path: Path.HOME,
		component: Home,
		name: Route.HOME,
		meta: { headerType: HeaderType.PUBLIC },
		beforeEnter: isNotSignedIn,
	},

	// Auth flow
	{
		path: Path.SIGNIN,
		component: Signin,
		name: Route.SIGNIN,
		meta: { headerType: HeaderType.PUBLIC, isDark: true, transition: "fade" },
		beforeEnter: isNotSignedIn,
	},
	{
		path: Path.SIGNUP,
		component: Signup,
		name: Route.SIGNUP,
		meta: { headerType: HeaderType.PUBLIC, isDark: true, transition: "fade" },
		beforeEnter: [isNotSignedIn, isValidSourceSet],
	},
	{
		path: Path.FORGOT_PASSWORD,
		component: ForgotPassword,
		name: Route.FORGOT_PASSWORD,
		meta: { headerType: HeaderType.PUBLIC, isDark: true, transition: "fade" },
		beforeEnter: isNotSignedIn,
	},
	{
		path: Path.RESET_PASSWORD,
		component: ResetPassword,
		name: Route.RESET_PASSWORD,
		meta: { headerType: HeaderType.PUBLIC, isDark: true, transition: "fade" },
		beforeEnter: isNotSignedIn,
	},

	// Onboarding
	{
		path: Path.ONBOARDING,
		component: Onboarding,
		name: Route.ONBOARDING,
		meta: { headerType: HeaderType.LOGOUT, isDark: true },
		beforeEnter: isInOnboarding,
		children: [
			{
				path: Path.ONBOARDING_STEP_APPLICATION,
				name: Route.ONBOARDING_STEP_APPLICATION,
				component: OnboardingStepApplication,
			},
			{
				path: Path.ONBOARDING_STEP_REVIEW,
				name: Route.ONBOARDING_STEP_REVIEW,
				component: OnboardingStepReview,
			},
			{
				path: Path.ONBOARDING_STEP_PACKAGE,
				name: Route.ONBOARDING_STEP_PACKAGE,
				component: OnboardingStepPackage,
			},
			{
				path: Path.ONBOARDING_STEP_MATES,
				name: Route.ONBOARDING_STEP_MATES,
				component: OnboardingStepMates,
			},
			{
				path: Path.ONBOARDING_STEP_PREFERENCES,
				name: Route.ONBOARDING_STEP_PREFERENCES,
				component: OnboardingStepPreferences,
			},
			{
				path: Path.ONBOARDING_STEP_CONTRACT,
				name: Route.ONBOARDING_STEP_CONTRACT,
				component: OnboardingStepContract,
			},
			{
				path: Path.ONBOARDING_STEP_PAYMENT,
				name: Route.ONBOARDING_STEP_PAYMENT,
				component: OnboardingStepPayment,
			},
			{
				path: Path.ONBOARDING_STEP_THANKS,
				name: Route.ONBOARDING_STEP_THANKS,
				component: OnboardingStepThanks,
			},
		],
	},

	// Onboarding docusign redirect
	{
		path: Path.ONBOARDING_DOCUSIGN_REDIRECT,
		component: OnboardingDocusignRedirect,
		name: Route.ONBOARDING_DOCUSIGN_REDIRECT,
		meta: { isEmptyLayout: true },
		beforeEnter: isInOnboarding,
	},

	// App
	{
		path: Path.SWITCH_ACCOUNT,
		component: SwitchAccount,
		name: Route.SWITCH_ACCOUNT,
		meta: { headerType: HeaderType.LOGOUT, isDark: true },
	},
	{
		path: Path.DASHBOARD,
		component: Dashboard,
		name: Route.DASHBOARD,
		meta: {
			permissions: [
				PermissionName.STAYS_LIST_MATES,
				PermissionName.STAYS_INVITE_RELATIVE,
				PermissionName.EVENTS_REGISTRATIONS_ME,
				PermissionName.EVENTS_LIST_AVAILABLE,
			],
			restrictions: [
				{
					for: [PermissionGroupPersona.RELATIVES],
					when: [StayStatus.ENDED, StayStatus.WAITING_REFUND],
				},
			],
		},
		beforeEnter: isNotInOnboarding,
	},
	{
		path: Path.DASHBOARD_BACKOFFICE,
		component: DashboardBackoffice,
		name: Route.DASHBOARD_BACKOFFICE,
		meta: { permissions: [PermissionName.DASHBOARD_BACKOFFICE_PAGE] },
		beforeEnter: isNotInOnboarding,
	},
	{
		path: Path.STATISTICS,
		component: Statistics,
		name: Route.STATISTICS,
		meta: { permissions: [PermissionName.STATISTICS_PAGE] },
		beforeEnter: isNotInOnboarding,
	},
	{
		path: Path.SPACES,
		component: Spaces,
		name: Route.SPACES,
		meta: { permissions: [PermissionName.SPACES_SERVICES_PAGE], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				component: SpacesList,
				name: Route.SPACES_LIST,
			},
			{
				path: Path.SPACE_VIEW,
				component: SpacesView,
				name: Route.SPACES_VIEW,
			},
		],
	},
	{
		path: Path.MY_PLAN,
		component: MyPlan,
		name: Route.MY_PLAN,
		meta: {
			permissions: [
				PermissionName.OPTIONS_LIST,
				PermissionName.STAYS_OPTIONS_LIST_ME,
				PermissionName.STAYS_OPTIONS_SUBSCRIBE,
				PermissionName.STAYS_OPTIONS_UNSUBSCRIBE,
				PermissionName.STAYS_OUTBOARDINGS_LEAVE,
				PermissionName.STAYS_UPDATE_MY_CODES,
			],
			restrictions: [{ for: [PermissionGroupPersona.STAFFS], when: allStatuses }],
		},
		beforeEnter: isNotInOnboarding,
	},
	{
		path: Path.DOCUMENTS,
		component: Documents,
		name: Route.DOCUMENTS,
		meta: {
			permissions: [PermissionName.DOCUMENTS_LIST, PermissionName.DOCUMENTS_RETRIEVE_ME],
			restrictions: [{ for: [PermissionGroupPersona.RELATIVES], when: allStatuses }],
		},
		beforeEnter: isNotInOnboarding,
	},
	{
		path: Path.BILLING,
		component: Billing,
		name: Route.BILLING,
		meta: { permissions: [PermissionName.BILLING_INVOICES_LIST_ME], transition: "fade" },
		beforeEnter: isNotInOnboarding,
	},
	{
		path: Path.SETTINGS,
		component: Settings,
		name: Route.SETTINGS,
		meta: {
			permissions: [
				PermissionName.USERS_UPDATE_ME,
				PermissionName.USERS_UPDATE_AVATAR,
				PermissionName.USERS_UPDATE_PASSWORD_ME,
				PermissionName.BADGE_REQUESTS_CREATE,
				PermissionName.WIFI_ACCOUNTS_RETRIEVE_ME,
				PermissionName.WIFI_ACCOUNTS_RESET_MY_PASSWORD,
			],
			restrictions: [
				{
					for: [
						PermissionGroupPersona.RELATIVES,
						PermissionGroupPersona.RESIDENTS,
						PermissionGroupPersona.ADMINISTRATORS,
						PermissionGroupPersona.OPERATORS,
						PermissionGroupPersona.STAFFS,
					],
					when: [
						StayStatus.APPLICATION,
						StayStatus.CANCELED,
						StayStatus.CONTRACT_PHASE,
						StayStatus.DELAYED,
						StayStatus.ENDED,
						StayStatus.EXPIRED,
						StayStatus.IN_REVIEW,
						StayStatus.WAITING_REFUND,
					],
				},
			],
		},
		beforeEnter: isNotInOnboarding,
	},
	{
		path: Path.HELP_CENTER,
		component: HelpCenter,
		name: Route.HELP_CENTER,
		meta: {
			permissions: [
				PermissionName.ZENDESK_GUIDE_SECTIONS,
				PermissionName.ZENDESK_ARTICLES_SEARCH,
				PermissionName.ZENDESK_CREATE_TICKET,
				PermissionName.ZENDESK_LIST_TICKETS,
			],
		},
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				component: HelpCenterList,
				name: Route.HELP_CENTER_LIST,
			},
			{
				path: Path.HELP_CENTER_SECTIONS,
				component: HelpCenterSections,
				name: Route.HELP_CENTER_SECTIONS,
				children: [
					{
						path: Path.HELP_CENTER_ARTICLES,
						component: HelpCenterArticles,
						name: Route.HELP_CENTER_ARTICLES,
					},
				],
			},
			{
				path: Path.HELP_CENTER_TICKETS,
				component: HelpCenterTickets,
				name: Route.HELP_CENTER_TICKETS,
				children: [
					{
						path: Path.HELP_CENTER_TICKETS_DETAILS,
						component: HelpCenterTicketsDetails,
						name: Route.HELP_CENTER_TICKETS_DETAILS,
					},
					{
						path: Path.HELP_CENTER_TICKETS_CREATE,
						component: HelpCenterTicketsCreate,
						name: Route.HELP_CENTER_TICKETS_CREATE,
					},
				],
			},
		],
	},
	{
		path: Path.DELIVERY_ADDRESS,
		component: DeliveryAddress,
		name: Route.DELIVERY_ADDRESS,
		meta: {
			permissions: [PermissionName.DELIVERY_ADDRESS_PAGE],
			restrictions: [
				{
					for: [
						PermissionGroupPersona.RELATIVES,
						PermissionGroupPersona.RESIDENTS,
						PermissionGroupPersona.ADMINISTRATORS,
						PermissionGroupPersona.OPERATORS,
						PermissionGroupPersona.STAFFS,
					],
					when: [
						StayStatus.APPLICATION,
						StayStatus.CANCELED,
						StayStatus.CONTRACT_PHASE,
						StayStatus.DELAYED,
						StayStatus.ENDED,
						StayStatus.EXPIRED,
						StayStatus.IN_REVIEW,
						StayStatus.WAITING_REFUND,
						StayStatus.CONFIRMED,
					],
				},
			],
		},
		beforeEnter: isNotInOnboarding,
	},
	{
		path: Path.API_TOKENS,
		component: ApiTokens,
		name: Route.API_TOKENS,
		meta: { permissions: [PermissionName.API_TOKENS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.API_TOKENS_LIST,
				component: ApiTokensList,
				meta: { permissions: [PermissionName.API_TOKENS_LIST] },
			},
			{
				path: Path.API_TOKENS_CREATE,
				name: Route.API_TOKENS_CREATE,
				component: ApiTokensCreate,
				meta: { permissions: [PermissionName.API_TOKENS_CREATE] },
			},
			{
				path: Path.API_TOKENS_EDIT,
				name: Route.API_TOKENS_EDIT,
				component: ApiTokensEdit,
				meta: { permissions: [PermissionName.API_TOKENS_UPDATE] },
			},
			{
				path: Path.API_TOKENS_DETAILS,
				name: Route.API_TOKENS_DETAILS,
				component: ApiTokensDetails,
				meta: { permissions: [PermissionName.API_TOKENS_RETRIEVE] },
			},
		],
	},
	{
		path: Path.ASSESSMENTS,
		component: Assessments,
		name: Route.ASSESSMENTS,
		meta: { permissions: [PermissionName.ASSESSMENTS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.ASSESSMENTS_LIST,
				component: AssessmentsList,
				meta: { permissions: [PermissionName.ASSESSMENTS_LIST] },
			},
			{
				path: Path.ASSESSMENTS_FILL,
				name: Route.ASSESSMENTS_FILL,
				component: AssessmentsFill,
				meta: { permissions: [PermissionName.ASSESSMENTS_FILL] },
			},
			{
				path: Path.ASSESSMENTS_SIGN,
				name: Route.ASSESSMENTS_SIGN,
				component: AssessmentsSign,
				meta: { permissions: [PermissionName.ASSESSMENTS_SIGN] },
			},
			{
				path: Path.ASSESSMENTS_CREATE,
				name: Route.ASSESSMENTS_CREATE,
				component: AssessmentsCreate,
				meta: { permissions: [PermissionName.ASSESSMENTS_CREATE] },
			},
			{
				path: Path.ASSESSMENTS_DETAILS,
				name: Route.ASSESSMENTS_DETAILS,
				component: AssessmentsDetails,
				meta: { permissions: [PermissionName.ASSESSMENTS_RETRIEVE] },
			},
		],
	},
	{
		path: Path.BADGE_REQUESTS,
		component: BadgeRequests,
		name: Route.BADGE_REQUESTS,
		meta: { permissions: [PermissionName.BADGE_REQUESTS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.BADGE_REQUESTS_LIST,
				component: BadgeRequestsList,
				meta: { permissions: [PermissionName.BADGE_REQUESTS_LIST] },
			},
			{
				path: Path.BADGE_REQUESTS_DETAILS,
				name: Route.BADGE_REQUESTS_DETAILS,
				component: BadgeRequestsDetails,
				meta: { permissions: [PermissionName.BADGE_REQUESTS_RETRIEVE] },
			},
		],
	},
	{
		path: Path.FLATS,
		component: Flats,
		name: Route.FLATS,
		meta: { permissions: [PermissionName.FLATS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.FLATS_LIST,
				component: FlatsList,
				meta: { permissions: [PermissionName.FLATS_LIST] },
			},
			{
				path: Path.FLATS_CREATE,
				name: Route.FLATS_CREATE,
				component: FlatsCreate,
				meta: { permissions: [PermissionName.FLATS_CREATE] },
			},
			{
				path: Path.FLATS_DETAILS,
				name: Route.FLATS_DETAILS,
				component: FlatsDetails,
				meta: { permissions: [PermissionName.FLATS_RETRIEVE] },
			},
			{
				path: Path.FLATS_EDIT,
				name: Route.FLATS_EDIT,
				component: FlatsEdit,
				meta: { permissions: [PermissionName.FLATS_UPDATE] },
			},
		],
	},
	{
		path: Path.ONBOARDING_SESSIONS,
		component: OnboardingSessions,
		name: Route.ONBOARDING_SESSIONS,
		meta: { permissions: [PermissionName.ONBOARDING_SESSIONS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.ONBOARDING_SESSIONS_LIST,
				component: OnboardingSessionsList,
				meta: { permissions: [PermissionName.ONBOARDING_SESSIONS_LIST] },
			},
			{
				path: Path.ONBOARDING_SESSIONS_CREATE,
				name: Route.ONBOARDING_SESSIONS_CREATE,
				component: OnboardingSessionsCreate,
				meta: { permissions: [PermissionName.ONBOARDING_SESSIONS_CREATE] },
			},
			{
				path: Path.ONBOARDING_SESSIONS_DETAILS,
				name: Route.ONBOARDING_SESSIONS_DETAILS,
				component: OnboardingSessionsDetails,
				meta: { permissions: [PermissionName.ONBOARDING_SESSIONS_RETRIEVE] },
			},
			{
				path: Path.ONBOARDING_SESSIONS_EDIT,
				name: Route.ONBOARDING_SESSIONS_EDIT,
				component: OnboardingSessionsEdit,
				meta: { permissions: [PermissionName.ONBOARDING_SESSIONS_UPDATE] },
			},
		],
	},
	{
		path: Path.PARKING_SPACES,
		component: ParkingSpaces,
		name: Route.PARKING_SPACES,
		meta: { permissions: [PermissionName.PARKING_SPACES_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.PARKING_SPACES_LIST,
				component: ParkingSpacesList,
				meta: { permissions: [PermissionName.PARKING_SPACES_LIST] },
			},
			{
				path: Path.PARKING_SPACES_CREATE,
				name: Route.PARKING_SPACES_CREATE,
				component: ParkingSpacesCreate,
				meta: { permissions: [PermissionName.PARKING_SPACES_CREATE] },
			},
			{
				path: Path.PARKING_SPACES_DETAILS,
				name: Route.PARKING_SPACES_DETAILS,
				component: ParkingSpacesDetails,
				meta: { permissions: [PermissionName.PARKING_SPACES_RETRIEVE] },
			},
			{
				path: Path.PARKING_SPACES_EDIT,
				name: Route.PARKING_SPACES_EDIT,
				component: ParkingSpacesEdit,
				meta: { permissions: [PermissionName.PARKING_SPACES_UPDATE] },
			},
		],
	},
	{
		path: Path.ROOMS,
		component: Rooms,
		name: Route.ROOMS,
		meta: { permissions: [PermissionName.ROOMS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.ROOMS_LIST,
				component: RoomsList,
				meta: { permissions: [PermissionName.ROOMS_LIST] },
			},
			{
				path: Path.ROOMS_CREATE,
				name: Route.ROOMS_CREATE,
				component: RoomsCreate,
				meta: { permissions: [PermissionName.ROOMS_CREATE] },
			},
			{
				path: Path.ROOMS_DETAILS,
				name: Route.ROOMS_DETAILS,
				component: RoomsDetails,
				meta: { permissions: [PermissionName.ROOMS_RETRIEVE] },
			},
			{
				path: Path.ROOMS_EDIT,
				name: Route.ROOMS_EDIT,
				component: RoomsEdit,
				meta: { permissions: [PermissionName.ROOMS_UPDATE] },
			},
		],
	},
	{
		path: Path.SOURCES,
		component: Sources,
		name: Route.SOURCES,
		meta: { permissions: [PermissionName.SOURCES_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.SOURCES_LIST,
				component: SourcesList,
				meta: { permissions: [PermissionName.SOURCES_LIST] },
			},
			{
				path: Path.SOURCES_CREATE,
				name: Route.SOURCES_CREATE,
				component: SourcesCreate,
				meta: { permissions: [PermissionName.SOURCES_CREATE] },
			},
			{
				path: Path.SOURCES_DETAILS,
				name: Route.SOURCES_DETAILS,
				component: SourcesDetails,
				meta: { permissions: [PermissionName.SOURCES_RETRIEVE] },
			},
			{
				path: Path.SOURCES_EDIT,
				name: Route.SOURCES_EDIT,
				component: SourcesEdit,
				meta: { permissions: [PermissionName.SOURCES_UPDATE] },
			},
		],
	},
	{
		path: Path.TOWERS,
		component: Towers,
		name: Route.TOWERS,
		meta: { permissions: [PermissionName.TOWERS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.TOWERS_LIST,
				component: TowersList,
				meta: { permissions: [PermissionName.TOWERS_LIST] },
			},
			{
				path: Path.TOWERS_CREATE,
				name: Route.TOWERS_CREATE,
				component: TowersCreate,
				meta: { permissions: [PermissionName.TOWERS_CREATE] },
			},
			{
				path: Path.TOWERS_DETAILS,
				name: Route.TOWERS_DETAILS,
				component: TowersDetails,
				meta: { permissions: [PermissionName.TOWERS_RETRIEVE] },
			},
			{
				path: Path.TOWERS_EDIT,
				name: Route.TOWERS_EDIT,
				component: TowersEdit,
				meta: { permissions: [PermissionName.TOWERS_UPDATE] },
			},
		],
	},
	{
		path: Path.STAYS,
		component: Stays,
		name: Route.STAYS,
		meta: { permissions: [PermissionName.STAYS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.STAYS_LIST,
				component: StaysList,
				meta: { permissions: [PermissionName.STAYS_LIST] },
			},
			{
				path: Path.STAYS_DETAILS,
				name: Route.STAYS_DETAILS,
				component: StaysDetails,
				meta: { permissions: [PermissionName.STAYS_RETRIEVE] },
			},
		],
	},
	{
		path: Path.WARNINGS,
		component: Warnings,
		name: Route.WARNINGS,
		meta: { permissions: [PermissionName.WARNINGS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.WARNINGS_LIST,
				component: WarningsList,
				meta: { permissions: [PermissionName.WARNINGS_LIST] },
			},
			{
				path: Path.WARNINGS_CREATE,
				name: Route.WARNINGS_CREATE,
				component: WarningsCreate,
				meta: { permissions: [PermissionName.WARNINGS_CREATE] },
			},
		],
	},
	{
		path: Path.EVENTS,
		component: Events,
		name: Route.EVENTS,
		meta: { permissions: [PermissionName.EVENTS_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.EVENTS_LIST,
				component: EventsList,
				meta: { permissions: [PermissionName.EVENTS_LIST] },
			},
			{
				path: Path.EVENTS_CREATE,
				name: Route.EVENTS_CREATE,
				component: EventsCreate,
				meta: { permissions: [PermissionName.EVENTS_CREATE] },
			},
			{
				path: Path.EVENTS_EDIT,
				name: Route.EVENTS_EDIT,
				component: EventsEdit,
				meta: { permissions: [PermissionName.EVENTS_UPDATE] },
			},
			{
				path: Path.EVENTS_DETAILS,
				name: Route.EVENTS_DETAILS,
				component: EventsDetails,
				meta: { permissions: [PermissionName.EVENTS_RETRIEVE] },
			},
		],
	},
	{
		path: Path.INVOICES,
		component: Invoices,
		name: Route.INVOICES,
		meta: { permissions: [PermissionName.BILLING_INVOICES_LIST], transition: "fade" },
		beforeEnter: isNotInOnboarding,
		children: [
			{
				path: "",
				name: Route.INVOICES_LIST,
				component: Invoiceslist,
				meta: { permissions: [PermissionName.BILLING_INVOICES_LIST] },
			},
		],
	},

	// Legals
	{
		path: Path.PRIVACY_POLICY,
		name: Route.PRIVACY_POLICY,
		component: PrivacyPolicy,
	},
	{
		path: Path.GCU,
		name: Route.GCU,
		component: GCU,
	},
	{
		path: Path.TERMS_OF_SERVICES,
		name: Route.TERMS_OF_SERVICES,
		component: TermsOfServices,
	},
	{
		path: Path.LEGAL_MENTIONS,
		name: Route.LEGAL_MENTIONS,
		component: LegalMentions,
	},

	// Other
	{ path: Path.NOT_FOUND, component: NotFound, name: Route.NOT_FOUND },
];
