import { getDefaultLocation } from "@/helpers/get-default-location";
import { useUserStore } from "@/stores/user.store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const isNotSignedIn = (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext,
) => {
	// Get access to the user store
	const userStore = useUserStore();

	// Continue if no user signed
	if (!userStore.getUser) return next();

	// Assess the default location to redirect to
	const location = getDefaultLocation();

	// Redirect the user to its current default location
	return next(location);
};
