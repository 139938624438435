import io from "socket.io-client";
import { StoreKey } from "@/enums/store-keys.enum";
import { defineStore } from "pinia";
import { SocketMessage } from "@/enums/socket-message.enum";

export const useSocketStore = defineStore(StoreKey.SOCKET, {
	state: () => ({
		ws: io(String(process.env.VITE_SOCKETS_URL), { transports: ["websocket"] }),
	}),

	actions: {
		emit(message: SocketMessage, body: unknown) {
			this.ws.emit(message, body);
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		listen(message: SocketMessage, callback: (...args: any[]) => void) {
			this.ws.on(message, callback);
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		listenOnce(message: SocketMessage, callback: (...args: any[]) => void) {
			this.ws.once(message, callback);
		},

		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		listenOff(message: SocketMessage, callback: (...args: any[]) => void) {
			this.ws.off(message, callback);
		},

		removeListeners(message: SocketMessage) {
			this.ws.removeAllListeners(message);
		},
	},
});
