import { Path } from "@/enums/path.enum";
import { PermissionName } from "@/enums/permission-name.enum";
import { notify } from "@/helpers/notify.helper";
import { useUserStore } from "@/stores/user.store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";
import { authorizedPages } from "@/helpers/authorized-pages.helper";

export const hasOneOfPermissions = (
	to: RouteLocationNormalized,
	_from: RouteLocationNormalized,
	next: NavigationGuardNext,
) => {
	// Get the user store instance
	const userStore = useUserStore();

	// Extract permissions linked to the current route from its meta
	const { permissions } = to.meta as { permissions: PermissionName[] };

	// If at least one permission is required to access this route & user doesn't have it
	if (permissions?.length > 0 && !userStore.hasOneOfPermissions(permissions as PermissionName[])) {
		// Trigger a notification explaining that user doesn't have the access rights to access the page
		notify(`You do not have access to the ${String(to.name)} page`);

		// Fetch list of authorized pages
		const authorizedPaths = authorizedPages();

		// Redirect user to the first page available or the home page if none
		return next(authorizedPaths[0]?.url || Path.HOME);
	}

	// Go through to route or next navigation guard
	return next();
};
