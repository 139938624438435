import { StayStatus } from "@/enums/stay-status.enum";
import { getDefaultLocation } from "@/helpers/get-default-location";
import { useUserStore } from "@/stores/user.store";
import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const isNotInOnboarding = (
	to: RouteLocationNormalized,
	from: RouteLocationNormalized,
	next: NavigationGuardNext,
) => {
	// Get access to the user store
	const userStore = useUserStore();

	// Continue if the current stay is not in an onboarding status
	if (
		userStore.currentStay &&
		[
			StayStatus.CONFIRMED,
			StayStatus.ARRIVING,
			StayStatus.LIVE,
			StayStatus.LEAVING,
			StayStatus.WAITING_REFUND,
			StayStatus.ENDED,
		].includes(userStore.currentStay?.status)
	)
		return next();

	// Assess the default location to redirect to
	const location = getDefaultLocation();

	// Redirect the user to its current default location
	return next(location);
};
